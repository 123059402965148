
$(document).ready(function () {

    $('.not-first').prop("disabled", true);

});

$(function () {
    'use strict';
    var body = $('body');

    function goToNextInput(e) {
        var key = e.which,
            t = $(e.target),
            sib = t.next('input');

        if (key === 9) {
            return true;
        }

        if (!sib || !sib.length) {
            sib = body.find('input').eq(0);
            $('.btn-verify').prop("disabled", false);
        }

        sib.select().removeAttr('disabled');
        sib.select().focus();

    }

    function onFocus(e) {
        $(e.target).select();
    }

    body.on('keyup', 'input', goToNextInput);
    body.on('click', 'input', onFocus);

});